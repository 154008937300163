import { isStaging } from './thread'

export default {
  PATH_ASSETS: process.env.ASSETS,
  PATH_CDN: process.env.CDN,
  PATH_DATA: window.metropolisInfo?.pathData,
  MAX_WIDTH: 1400,
  INDEX_DB_DATABASE_NAME: 'metropolisDatabase',
  CACHE_BUST_VERSION: window.metropolisInfo?.cacheVersion || 0,
  API_ID: '7d7618f6-8dba-4e47-b887-13b37da34640',
  API_URL: window.metropolisInfo?.api || 'https://api.metropolisx.io',
  API_V2_URL: window.metropolisInfo?.apiV2 || 'https://api2.metropolisx.io',
  GTM_ID: window.metropolisInfo?.gtmId,
  CHAIN: isStaging ? 80002 : 137,
  NETWORK: isStaging ? 'testnet' : 'mainnet',
  WALLET_CONNECT_PROJECT_ID: '65b605400e4fd043c7468835d41d2f5f',
  TEAM_FINANCE_CHAIN_ID: 137,
  TEAM_FINANCE_POOL_ADDRESS: '0xd6a07b8065f9e8386a9a5bba6a754a10a9cd1074',
  TEAM_FINANCE_POOL_ID: 426,
  TEAM_FINANCE_TOKEN_ADDRESS: '0x2aB445C24C96dB13383Bb34678aDaE50c43B4BAA',
  TEAM_FINANCE_API_KEY: 'd905a36951d1a14294d71a598dee9098',

  // The Metropolis wallet
  METROPOLIS_WALLET: '0x745a2BC8CF1b8D53d3487d5a1F89eF92CbFdEf70'
}
