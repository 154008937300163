import { memo } from 'react'
import Modal from '@/components/interface/Modal/Modal'
import SocialShare from '@/components/interface/SocialShare/SocialShare'

/**
 * The `ModalShare`
 * @param props
 * @returns {React.ReactElement} the element
 */
function ModalShare (props) {
  return (
    <Modal.Container>
      <Modal.Header>Share this</Modal.Header>
      <Modal.Body>
        <SocialShare {...props} />
      </Modal.Body>
    </Modal.Container>
  )
}

export default memo(ModalShare)
