import PropTypes from 'prop-types'
import { forwardRef, memo, useMemo } from 'react'
import Spinner from '@/components/elements/Spinner/Spinner'
import useFallbackRef from '@/lib/react/hooks/useFallbackRef'
import usePressEvents from '@/lib/react/hooks/usePressEvents'
import classNames from '@/lib/util/classNames'
import { normalizeSizeString } from '@/lib/util/sizes'
import Icon from '../Icon/Icon'
import styles from './Button.module.scss'

/**
 * The `Button`
 * @param {object} props - the component props
 * @returns {React.ReactElement} the element
 */
const Button = forwardRef((props, forwardedRef) => {
  const {
    Tag = 'button',
    active,
    children,
    className,
    icon,
    isBusy = false,
    secondary = false,
    side = 'right',
    size = 'standard',
    tertiary = false,
    ...rest
  } = props
  const ref = useFallbackRef(forwardedRef)
  const isPressing = usePressEvents(ref, false)
  const classNameOutput = useMemo(
    () =>
      classNames(
        className,
        styles.container,
        isPressing && styles.press,
        active && styles.active,
        styles[`size-${normalizeSizeString(size)}`],
        styles[`side-${side}`],
        secondary && styles.secondary,
        tertiary && styles.tertiary,
        icon && styles.hasIcon
      ),
    [className, isPressing, active, size, icon, secondary, tertiary, side]
  )

  const output = useMemo(() => {
    if (isBusy) {
      return <Spinner className={styles.spinner} delayShow={0} />
    }

    return (
      <>
        {icon && (
          <>
            <Icon
              className={classNames(styles.icon, styles.before)}
              icon={icon}
            />
            <span className={styles.content}>{children}</span>
            <Icon
              className={classNames(styles.icon, styles.after)}
              icon={icon}
            />
          </>
        )}
        {!icon && children}
      </>
    )
  }, [icon, isBusy, children])

  return (
    <Tag ref={ref} className={classNameOutput} {...rest}>
      {output}
    </Tag>
  )
})

// Display name
Button.displayName = 'Button'

/** @type {object} */
Button.propTypes = {
  Tag: PropTypes.elementType,
  active: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
  icon: PropTypes.elementType,
  size: PropTypes.string,
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  tertiary: PropTypes.bool,
  isBusy: PropTypes.bool,
  side: PropTypes.string
}

// Memoize
export default memo(Button)
