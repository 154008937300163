import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { useRootContext } from '@/app/services/RootProvider/RootProvider'
import Icon from '@/components/core/Icon/Icon'
import classNames from '@/lib/util/classNames'
import { getTextNodes, getTextRowRects } from '@/lib/util/text'
import { useModalContext } from '../Modal/provider/ModalProvider'
import styles from './NavLink.module.scss'

/**
 * The `NavLink` component
 * @param {object} props - the component props
 * @returns {React.ReactElement} the element
 */
export default function NavLink (props) {
  const {
    action = 'default',
    activeStyle = 'highlight',
    childLinks = [],
    children,
    className,
    external,
    href,
    icon,
    invert = false,
    modalId = null,
    noMargin = false,
    onClick = null,
    ...rest
  } = props

  const ref = useRef()
  const labelRef = useRef()
  const { showModal } = useModalContext()
  const { route } = useRootContext()
  const [isHover, setIsHover] = useState(false)
  const [isHoverIn, setIsHoverIn] = useState(false)
  const [isHoverOut, setIsHoverOut] = useState(false)
  const lastHover = useRef(false)
  const isTimerRunning = useRef(false)
  const didHoverIn = useRef(false)
  const timer = useRef()
  const Tag = useMemo(
    () =>
      action !== 'default' || !!onClick ? 'button' : external ? 'a' : Link,
    [external, action, onClick]
  )
  const linkConfig = useMemo(
    () =>
      action !== 'default' || !!onClick
        ? {}
        : !external
            ? { to: href }
            : { href, target: '_blank' },
    [external, href]
  )
  const isActiveLink = useMemo(
    () =>
      !!route?.path?.exec(href) ||
      childLinks?.some(link => route?.path?.exec(link.href)),
    [route, href, childLinks]
  )

  const classNameOutput = useMemo(
    () =>
      classNames(
        className,
        styles.container,
        isActiveLink && styles.active,
        ((activeStyle === 'highlight' && isActiveLink) || isHoverIn) &&
          styles.in,
        isHoverOut && styles.out,
        icon && styles.hasIcon,
        invert && styles.invert,
        styles[`active-style-${activeStyle}`],
        noMargin && styles.noMargin
      ),
    [
      className,
      isActiveLink,
      isHoverOut,
      isHoverIn,
      icon,
      invert,
      activeStyle,
      noMargin
    ]
  )

  useEffect(() => {
    if (lastHover.current === isHover) {
      return
    }

    lastHover.current = isHover

    if (isHover) {
      setIsHoverOut(false)

      timer.current = setTimeout(() => {
        setIsHoverIn(true)
        isTimerRunning.current = false
        didHoverIn.current = true
      }, 100)

      isTimerRunning.current = true
    } else {
      if (didHoverIn.current) {
        setIsHoverIn(false)
        setIsHoverOut(true)

        timer.current = setTimeout(() => {
          setIsHoverOut(false)
          isTimerRunning.current = false
        }, 300)

        isTimerRunning.current = true
        didHoverIn.current = false
      }
    }

    return () => clearTimeout(timer.current)
  }, [isHover])

  const handlePointerEnter = useCallback(() => {
    setIsHover(true)
  }, [])

  const handlePointerLeave = useCallback(() => {
    setIsHover(false)
  }, [])

  const handleClick = useCallback(
    e => {
      if (onClick) {
        onClick(e)

        return
      }

      if (action === 'modal' && !!modalId) {
        e.preventDefault()

        showModal({
          id: modalId
        })
      }
    },
    [action, modalId, onClick]
  )

  useEffect(() => {
    lastHover.current = false
  }, [])

  useEffect(() => {
    const textNodes = getTextNodes(labelRef.current)
    let frame

    const update = () => {
      const { rowRects } = getTextRowRects(textNodes)
      const lastRow = rowRects[rowRects.length - 1]

      ref.current.style.setProperty('--bar-width', lastRow?.width + 'px')
    }

    const handleResize = () => {
      cancelAnimationFrame(frame)

      frame = requestAnimationFrame(update)
    }

    const observer = new ResizeObserver(handleResize)

    observer.observe(labelRef.current)

    return () => {
      cancelAnimationFrame(frame)
      observer.disconnect()
    }
  }, [children])

  return (
    <Tag
      className={classNameOutput}
      ref={ref}
      {...linkConfig}
      {...rest}
      onMouseEnter={handlePointerEnter}
      onMouseLeave={handlePointerLeave}
      onClick={handleClick}
    >
      {icon && (
        <div className={styles.icon}>
          <Icon icon={icon} />
        </div>
      )}
      <div className={styles.content}>
        <span ref={labelRef} className={styles.label}>
          {children}
        </span>
        <span aria-hidden="true" className={styles.bar} />
      </div>
    </Tag>
  )
}

NavLink.propTypes = {
  action: PropTypes.string,
  activeStyle: PropTypes.string,
  className: PropTypes.string,
  Tag: PropTypes.elementType,
  childLinks: PropTypes.arrayOf(PropTypes.shape()),
  children: PropTypes.node,
  icon: PropTypes.string,
  external: PropTypes.bool,
  href: PropTypes.string,
  invert: PropTypes.bool,
  modalId: PropTypes.string,
  noMargin: PropTypes.bool,
  onClick: PropTypes.func
}
