/**
 * Get a social network share link
 * @param {string} network - The social network id
 * @param {string} [message] - Optional message to include in the share
 * @returns {string} The URL
 */
const getSocialNetworkShareLink = (network, message = '') => {
  const url = window.location.href
  const encodedUrl = encodeURIComponent(url)
  const encodedMessage = message ? encodeURIComponent(message) : ''

  if (network === 'twitter') {
    return `https://twitter.com/intent/tweet?url=${encodedUrl}${
      encodedMessage ? `&text=${encodedMessage}` : ''
    }`
  }

  if (network === 'facebook') {
    return `https://www.facebook.com/sharer.php?u=${encodedUrl}`
  }

  if (network === 'linkedin') {
    return `https://www.linkedin.com/shareArticle?mini=true&url=${encodedUrl}${
      encodedMessage ? `&summary=${encodedMessage}` : ''
    }`
  }

  return url
}

export default getSocialNetworkShareLink
