import PropTypes from 'prop-types'
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Button from '@/components/core/Button/Button'
import copyToClipboard from '@/lib/util/copyToClipboard'
import getSocialNetworkShareLink from '@/lib/util/getSocialNetworkShareLink'
import styles from './SocialShare.module.scss'

const NETWORKS = [
  { type: 'twitter', title: 'Twitter', icon: 'twitter' },
  { type: 'facebook', title: 'Facebook', icon: 'facebook' },
  { type: 'linkedin', title: 'LinkedIn', icon: 'linkedin' }
]

/**
 * The `SocialShare`
 * @param {object} props - the component props
 * @returns {React.ReactElement} the element
 */
function SocialShare (props) {
  const { message = null } = props
  const [hasCopiedLink, setHasCopiedLink] = useState(false)
  const timeoutCopy = useRef()
  const networks = useMemo(
    () =>
      NETWORKS.map(network => ({
        ...network,
        url: getSocialNetworkShareLink(network.type, message),
        label: {}
      })),
    [message]
  )
  const labelCopyLink = useMemo(
    () => (hasCopiedLink ? 'Copied!' : 'Copy link'),
    [hasCopiedLink]
  )

  const handleCopyLink = useCallback(() => {
    clearTimeout(timeoutCopy.current)

    const url = getSocialNetworkShareLink('link')
    const success = copyToClipboard(url)

    if (!success) {
      setHasCopiedLink(false)

      return
    }

    setHasCopiedLink(true)

    timeoutCopy.current = setTimeout(() => {
      setHasCopiedLink(false)
    }, 1000)
  }, [])

  useEffect(
    () => () => {
      clearTimeout(timeoutCopy.current)
    },
    []
  )

  return (
    <div className={styles.content}>
      <ul className={styles.options}>
        {networks.map(({ icon, title, url }, i) => (
          <li key={i}>
            <Button icon={icon} size="lg" Tag="a" href={url} target="_blank">
              {title}
            </Button>
          </li>
        ))}
        <li>
          <Button size="lg" icon="link" onClick={handleCopyLink}>
            {labelCopyLink}
          </Button>
        </li>
      </ul>
    </div>
  )
}

SocialShare.propTypes = {
  message: PropTypes.string
}

export default memo(SocialShare)
