/**
 * Copy to clipboard
 * @param {string} text - the text to copy
 * @returns {void} whether executed
 */
const copyToClipboard = text => {
  if (window.clipboardData && window.clipboardData.setData) {
    // IE specific code path to prevent textarea being shown while dialog is visible.
    return window.clipboardData.setData('Text', text)
  } else if (
    document.queryCommandSupported &&
    document.queryCommandSupported('copy')
  ) {
    const textarea = document.createElement('textarea')

    textarea.textContent = text
    textarea.style.position = 'fixed'
    document.body.appendChild(textarea)
    textarea.select()

    try {
      return document.execCommand('copy')
    } catch (ex) {
      console.warn('Copy to clipboard failed.', ex)

      return false
    } finally {
      document.body.removeChild(textarea)
    }
  }
}

export default copyToClipboard
